.input_item {
  margin-bottom: 15px;
}
.input_item .coordinates {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_item {
    flex: 0 0 50%;
  }
}
.coordinates_item + .coordinates_item {
  margin-left: 7px;
}
.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #006fc0;

  opacity: 0.8;
}
.points {
  margin: 25px 0;
}
.points_title {
  display: flex;
  padding: 10px 0;
  span {
    margin-left: 10px;
    font-size: 12px;
    color: #006fc0;

    cursor: pointer;
  }
}
.add_btn {
  margin-bottom: 20px;
  background-color: #ffffff;
}
.button_wrapper {
  display: flex;
  justify-content: flex-end;
}
.submit_btn {
  margin-left: 10px !important;
}
.confirm_modal {
  & .text {
    margin: 0 0 30px;
    text-align: left;
  }
  & .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & .cancel_btn {
    margin: 0 10px 0 20px;
  }
}
