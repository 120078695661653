.table_wrapper {
  margin: 50px 0;
}
.title {
  margin-bottom: 15px;
}
.table {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  th {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
  }
  td {
    font-size: 14px;
    line-height: 22px;
  }
  th,
  td {
    padding: 12px;
  }
  a {
    color: #006fc0 !important;
    text-decoration: underline;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  min-width: 55px;
  margin-right: -10px;
  .btn {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
}
