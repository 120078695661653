.merch {
  height: 100%;
  .content {
    padding-top: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.table_wrapper {
  margin: 0 0 40px;
}

.table {
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  th {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
  }
  td {
    font-size: 14px;
    line-height: 22px;
  }
  th,
  td {
    padding: 12px;
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.btn {
  position: relative;
  z-index: 11;
  padding: 0 !important;
  align-self: flex-end;
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  background-color: #fff !important;
  &_icon {
    color: #006fc0 !important;
    font-size: 37px !important;
  }
  &_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-right: 8px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 65px;
  margin-right: -20px;
  .btn {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
  a {
    margin-left: 10px;
    display: inline-block !important;
    width: 24px !important;
    height: 24px !important;
  }
}

.no_cards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: calc(100% - 74px);
  top: 74px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
