.dialog {
  width: 650px !important;
  padding: 20px;
}
.dialog_paper {
  max-width: 100% !important;
}
.form {
  width: 100%;
}
