.wrapper {
  padding: 130px 0;
}

.new {
  &__tabs {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 33px;
  }
  &__tab {
    margin-right: 16px;
    cursor: pointer;
  }
}

.active {
  color: rgba(0, 111, 192, 0.87);
}

.table {
  margin-bottom: 50px;

  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  th {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
  }
  td {
    font-size: 14px;
    line-height: 22px;
  }
  th,
  td {
    padding: 12px;
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.users_container {
  display: grid;
  column-gap: 100px;
  row-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 20px;
}

.user_item {
  width: min-content;
  padding-left: 20px;
  position: relative;
}

.user_title {
  white-space: nowrap;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.5;
  margin-bottom: 4px;
}

.user_icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
