.dialog {
  width: 788px !important;
  padding: 34px 38px 26px 39px;
}
.dialog_paper {
  max-width: 100% !important;
}
.form {
  width: 100%;
}
.title {
  align-self: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #006fc0;
  margin-bottom: 62px !important;
}
.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #006fc0;
  opacity: 0.8;
  padding: 0;
  margin: 0;
}
.info {
  &-text {
    span {
      font-weight: bold;
    }
  }
}

.input_item {
  width: 100%;
  margin-bottom: 30px;
  label {
    display: block;
    margin-bottom: 15px;
  }
}
.input {
  width: 100%;
}
.button_wrapper {
  text-align: right;
}
.submit_btn {
  height: 42px;
  color: white !important;
  background-color: #006fc0 !important;
  &:disabled {
    background: #7b7b7b !important;
  }
}
