.routes {
  height: 100%;
  .content {
    padding-top: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.header {
  position: relative;
  z-index: 11;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.back_link {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #006fc0 !important;

  span {
    margin: 2px 0 0 5px;
  }
}

.btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.filters {
  display: flex;
  justify-content: space-between;
  width: 420px;
  margin-bottom: 20px;
}

.btn {
  padding: 0 !important;
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff !important;
  & + .btn {
    margin-left: 15px;
  }
  &_icon {
    color: #006fc0 !important;
    font-size: 37px !important;
  }
  &_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-right: 8px;
  }
}

.btn_download,
.btn_upload {
  .btn_icon {
    margin-left: 5px;
    font-size: 27px !important;
  }
  .btn_text {
    font-size: 12px;
    text-transform: uppercase;
  }
  &:disabled {
    background-color: rgb(203, 203, 203) !important;

    .btn_text,
    .btn_icon {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }
}

.btn_download {
  .btn_text,
  .btn_icon {
    color: #01950e !important;
  }
}
.btn_upload {
  .btn_text,
  .btn_icon {
    color: #012195 !important;
  }
}

.no_cards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: calc(100% - 74px);
  top: 74px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.text {
  margin-top: 80px;
  text-align: center;
}
.confirm_modal {
  & .text {
    margin: 0 0 30px;
    text-align: left;
  }
  & .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & .cancel_btn {
    margin: 0 10px 0 20px;
  }
}
