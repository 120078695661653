.MainContainer {
  min-height: 100vh;
  padding-top: 74px;

  background: #f5f5f5;
}

.header {
  position: fixed;
  top: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 74px;
  padding: 10px 25px;

  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
}

.loader {
  position: fixed;
  z-index: 100;

  width: 100%;
}

.logo {
  position: relative;
  width: 194px;
  height: 52px;
  margin-top: -6px;

  text-align: center;
}

.ul {
  display: flex;
  align-items: baseline;
}

.link,
.logout {
  padding: 2px;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;

  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #006fc0;
  }
}

.link {
  margin-right: 40px;

  opacity: 0.6;

  &.active {
    opacity: 1;
  }
}

.logout {
  display: flex;
  align-items: center;

  &__img {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.container {
  margin: 0 auto;
  max-width: 1050px; //709
}
